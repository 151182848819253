import React from 'react';
import meNow from '../../assets/pictures/currentme.jpg';
import oldme from '../../assets/pictures/oldme.jpg';
import { Link } from 'react-router-dom';
import ResumeDownload from './ResumeDownload';

export interface AboutProps {}

const About: React.FC<AboutProps> = (props) => {
    return (
        // add on resize listener
        <div className="site-page-content">
            {/* <img src={me} style={styles.topImage} alt="" /> */}
            <h1 style={{ marginLeft: -16 }}>Welcome</h1>
            <h3>I'm Sayak Bhattacharyya</h3>
            <br />
            <div className="text-block">
                <p>
                    I'm a web developer currently actively looking for a job. In June
                    of 2024 I graduated from Techno International Batanagar
                    with my B.Tech in Computer Science & Engineering.
                </p>
                <br />
                <p>
                    Thank you for taking the time to check out my portfolio. I
                    really hope you enjoy exploring it as much as I enjoyed
                    building it. If you have any questions or comments, feel
                    free to contact me using this{' '}
                    <Link to="/contact"> form</Link> or shoot me an email at{' '}
                    <a href="mailto:sayak.helan@gmail.com">
                        sayak.helan@gmail.com
                    </a>
                </p>
            </div>
            <ResumeDownload />
            <div className="text-block">
                <h3>About Me</h3>
                <br />
                <p>
                My journey commenced in childhood, where my fascination with technology was nurtured by supportive parents who encouraged exploration and learning. I vividly remember spending countless hours tinkering with gadgets, eagerly absorbing any knowledge I could glean about computers and programming languages. It was during these formative years that I discovered my passion for coding—a passion that would shape my future endeavors.
                </p>
                <br />
                <div className="captioned-image">
                        <img src={oldme} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Figure 1:</b> The days I missed the most (May, 2008)
                            </sub>
                        </p>
                    </div>
                <p>
                As I progressed through school, my interest in technology only deepened. I eagerly enrolled in computer science courses, relishing the challenges they presented and reveling in the satisfaction of solving intricate problems. With each passing year, my skills as a programmer grew, fueled by a relentless drive to excel in a field that ignited my imagination. During this journey {' '}<Link to="/art">Photography</Link> came into my life.
                </p>
                <br />
                <p>
                Upon reaching college, I knew without a doubt that I wanted to pursue a career in software development. Armed with determination and a thirst for knowledge, I delved into my studies with fervor, immersing myself in a curriculum designed to cultivate the next generation of tech-savvy innovators. I have done various {' '} <Link to="/projects">Projects</Link>. From mastering complex algorithms to honing my proficiency in various programming languages, every lesson and project brought me one step closer to realizing my dream.
                </p>
                <br />
                <br />
                <div style={{}}>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'justify',
                            alignSelf: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <h3>My Hobbies</h3>
                        <br />
                        <p>
                            Beyond software, I have a lot of hobbies that I
                            enjoy doing in my free time. The more tangible
                            hobbies I have are{' '}
                            <Link to="/art">Photography</Link>{' '}
                            and listening to {' '}
                            <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://open.spotify.com/user/31rh7y2zjos2te5i7re6jkuit4bq">Music</a>. 
                            You can
                            know more about each of these on their respective
                            pages under their respective tabs. Some other hobbies I
                            enjoy are working out, hanging out with friends, and (unsurprisingly)
                            playing video games.
                        </p>
                        <br />
                        <p>
                        As a dedicated member of my college's photography club, I enthusiastically captured the vibrant spirit of campus life through my lens. Through countless shutter clicks. I sought to evoke emotion and tell compelling stories with each photograph. My involvement in the photography club not only enriched my college experience but also fostered a deep appreciation for the art of visual storytelling.
                        </p>
                    </div>
                    <div style={styles.verticalImage}>
                        <img src={meNow} style={styles.image} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> Me, January 2024
                            </sub>
                        </p>
                    </div>
                </div>
                <br />
                <br />
                <p>
                    Thanks for reading about me! I hope that you enjoy exploring
                    the rest of my portfolio website and everything it has to
                    offer. There are many easter eggs. If you find the easter eggs make sure to let me know
                    on twitter{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/aami_Sayak"
                    >
                        @aami_sayak
                    </a>{' '}
                    Good luck and have fun!
                </p>
                <br />
                <p>
                    If you have any questions or comments I would love to hear
                    them. You can reach me through the{' '}
                    <Link to="/contact">contact</Link> page or shoot me an email
                    at{' '}
                    <a href="mailto:sayak.helan@gmail.com">
                        sayak.helan@gmail.com
                    </a>
                </p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    contentHeader: {
        marginBottom: 16,
        fontSize: 48,
    },
    image: {
        height: 'auto',
        width: '100%',
    },
    topImage: {
        height: 'auto',
        width: '100%',
        marginBottom: 32,
    },
    verticalImage: {
        alignSelf: 'center',
        // width: '80%',
        marginLeft: 32,
        flex: 0.8,

        alignItems: 'center',
        // marginBottom: 32,
        textAlign: 'center',
        flexDirection: 'column',
    },
};

export default About;
