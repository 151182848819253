import React from 'react';
// @ts-ignore
import two from '../../../assets/pictures/projects/two.mp4';
// @ts-ignore
import flappy from '../../../assets/pictures/projects/flappy.mp4';
// @ts-ignore
import pacman from '../../../assets/pictures/projects/pacman.mp4';
// @ts-ignore
import chess from '../../../assets/pictures/projects/chess.mp4';
// @ts-ignore
import dino from '../../../assets/pictures/projects/dino.mp4';
// @ts-ignore
import space from '../../../assets/pictures/projects/space.mp4';
import { Link } from 'react-router-dom';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface MusicProjectsProps {}

const MusicProjects: React.FC<MusicProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Games</h1>
            <h3>Favourite Time-Pass Medium</h3>
            <br />
            <p>
                Below are some of my favorite game projects I have worked on
                over the last few years.
            </p>
            <br />
            <ResumeDownload />
            <br />
            <div className="text-block">
                <h2>Flappy 2048</h2>
                <br />
                <p>
                "Flappy-2048," an exciting fusion of two beloved games, Flappy Bird and 2048, crafted with the magic of JavaScript and CSS. In this innovative creation, players embark on a thrilling journey that combines the addictive gameplay of Flappy Bird with the strategic challenge of 2048.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={two} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> Game Scene from Flappy 2048
                        </sub>
                    </p>
                </div>
                <p>
                Set in a vibrant pixelated world, Flappy-2048 challenges players to navigate a familiar Flappy Bird-style environment while strategically merging numbered tiles, just like in the classic 2048 game. As players guide their bird through a series of obstacles, they must also strategically combine matching numbered tiles to reach the coveted 2048 tile and achieve the highest score possible.

With its seamless integration of gameplay mechanics from both Flappy Bird and 2048, Flappy-2048 offers a unique and captivating gaming experience that will keep players hooked for hours on end.
                </p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://flappy2048.pages.dev/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://flappy2048.pages.dev/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Clumsy Bird</h2>
                <br />
                <p>
                    
Flappy Bird, a beloved classic in the realm of mobile gaming, has been brought to life anew through the power of MelonJS. MelonJS, a robust HTML5 game engine, provides the foundation for this recreation, offering developers a seamless platform to create immersive gaming experiences.
                </p>
                <br />
                <p>In this rendition of Flappy Bird, players are thrust into an addictive challenge where timing and precision are paramount. Using simple controls, they navigate a pixelated bird through a series of obstacles, tapping to flap its wings and keep it airborne. The game's minimalist aesthetic, reminiscent of its predecessor, adds to its nostalgic charm while showcasing the capabilities of MelonJS in rendering crisp visuals and smooth animations.
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={flappy} />
                        <p style={styles.caption}>
                            <sub>
                                <b>Figure 2: </b> The Flappy Bird gameplay
                            </sub>
                        </p>
                </div>
                <p>
                Moreover, MelonJS ensures cross-platform compatibility, enabling players to enjoy Flappy Bird on a variety of devices, whether on desktop browsers or mobile devices. With its responsive design and optimized performance, the game delivers a seamless experience across different screen sizes and resolutions, ensuring that players can immerse themselves in the addictive gameplay wherever they go.
                </p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://clumsy-bird.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://clumsy-bird.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Pacman -recoded</h2>
                <br />
                <p>
                The development of the Pacman game using HTML5, CSS, and JavaScript was an exhilarating journey of creativity and problem-solving. With HTML5 providing the structural backbone, CSS for styling, and JavaScript for interactivity.

Starting with conceptualizing the game mechanics and visual design, I dove into coding, meticulously crafting each element to ensure smooth gameplay and an engaging user experience. JavaScript handled player controls, enemy behavior, and game logic, while CSS brought vibrant visuals to life, from colorful sprites to dynamic animations.</p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={pacman} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> Game Scene from the Pacman game
                        </sub>
                    </p>
                </div>
                <p>
                Throughout the process, I faced many challenges such as optimizing performance, tweaking algorithms for enemy AI, and ensuring compatibility across different browsers and devices. But with determination and collaboration, able to overcame these hurdles, fine-tuning every aspect until a polished final product achieved.

In the end, the Pacman game stands as a testament to the power of HTML5, CSS, and JavaScript in bringing beloved classics into the modern era, offering players a nostalgic trip down memory lane with a fresh twist.</p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://minepacman.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://minepacman.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>GM Chess</h2>
                <br />
                <p>  
                Creating a chess game with built-in AI using React script involves several key steps. Initially, designed the game's user interface with React components, ensuring a seamless and intuitive experience for players.
                </p>
                <br />
                <p>Next, implemented the chess logic, including movement rules and game state management. Integrating the AI involves coding algorithms for evaluating board positions and making strategic decisions. The React script facilitates dynamic updates to the UI based on game events and AI moves, providing players with a responsive and engaging gameplay experience. Testing and refining the game ensure smooth functionality and challenging gameplay before deployment.
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={chess} />
                        <p style={styles.caption}>
                            <sub>
                                <b>Figure 4: </b> The GM Chess gameplay
                            </sub>
                        </p>
                </div>
                <p>
                You play as the white player, and black is controlled by a simple chess AI (implemented using the minimax algorithm). Most of the rules of chess, from castling to not being able to put yourself in check, are fully implemented. Draws and time limits are not enabled. I also implemented time travel, so that users can view previous moves.
                </p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://gm-chess.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://gm-chess.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Telescope Designer</h2>
                <br />
                <p>
                Telescope Designer is a web-based application that aims to give students a chance to experience the issues involved in creating their own space telescope. Several mission scenarios are provided and each comes with its own set of requirements including cost, scientific goals, and even demands on specific launch vehicles. Building a mission means balancing these requirements.
                </p>
                <br />
                <p>There are two levels of difficulty - normal and advanced.In normal mode there are fewer options and, assuming you are given the go to launch, your mission is far more likely to be a success. In the advanced mode, there are more options available and more realistic ideas about risk so, just like in real life, risky parts of your mission could fail after all the hard work!
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={space} />
                        <p style={styles.caption}>
                            <sub>
                                <b>Figure 5: </b> The Telescope Designer gameplay
                            </sub>
                        </p>
                </div>
                <p>
                I realise that everyone's preference aren't same. So, you have the option to change the units.I'd love to hear your feedback about Telescope Designer. Please contact with me with your review.
                </p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://space-telescope.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://space-telescope.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>My Dino</h2>
                <br />
                <p>
                The creation of the Google Dino game involved a collaborative effort among many techs utilizing HTML5, CSS, and JavaScript. HTML5 provided the structure and layout of the game, CSS was used for styling and visual design, while JavaScript handled the game logic and interactivity. The process likely involved brainstorming the game concept, designing the visual elements, coding the gameplay mechanics, and testing for functionality and performance.</p>
                <br />
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={dino} />
                        <p style={styles.caption}>
                            <sub>
                                <b>Figure 6: </b> My Dino gameplay
                            </sub>
                        </p>
                </div>
                <p>Through iterative development and refinement, tried my best to brought the iconic T-Rex runner game to life, delivering an engaging and addictive experience playable across various web browsers.</p>
                <br />
                <h3>Link:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://404.imsayak.com/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://404.imsayak.com/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
             <div className="text-block">
                <p>
                    If you have any queries or any job offer then without any hesitation feel free to connect me through
                    {' '}
                    <a href="mailto:sayak.helan@gmail.com">
                        email</a>. Or via <Link to="/contact">contact</Link> form 😉.
                </p>
            </div>
            <ResumeDownload />
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default MusicProjects;
