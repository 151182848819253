import React from 'react';
import b1 from '../../assets/pictures/art/b1.jpg';
import b2 from '../../assets/pictures/art/b2.jpg';
import b3 from '../../assets/pictures/art/b3.jpg';
import b4 from '../../assets/pictures/art/b4.jpg';
import b5 from '../../assets/pictures/art/b5.jpg';
import p1 from '../../assets/pictures/art/p1.jpg';
import p2 from '../../assets/pictures/art/p2.jpg';
import p3 from '../../assets/pictures/art/p3.jpg';
import p4 from '../../assets/pictures/art/p4.jpg';
import p5 from '../../assets/pictures/art/p5.jpg';
import p6 from '../../assets/pictures/art/p6.jpg';
import s1 from '../../assets/pictures/art/s1.jpg';
import p7 from '../../assets/pictures/art/p7.jpg';
import s2 from '../../assets/pictures/art/s2.jpg';
import s3 from '../../assets/pictures/art/s3.jpg';
import s4 from '../../assets/pictures/art/s4.jpg';
export interface ArtProps {}

const Art: React.FC<ArtProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Photography</h1>
            <h2>Hobby</h2>
            <br />
            <div className="text-block">
                <p>
                    While I love programming and software development, Art & Photography will always hold a special place in my
                    heart.
                </p>
                <br />
                <p><h3>Photography Gears:</h3>
                <ul>
                    <li>Nikon D5500</li>
                    <li>Nikon AF-P DX Nikkor 18 - 55 mm f/3.5-5.6G VR</li>
                    <li>AF-P DX Nikkor 70-300 mm F/4.5-6.3G ED VR</li>
                    <li>Nikon AF-S Nikkor 50 mm F/1.8G Prime</li>
                    <li>Sony DSC-RX100M3</li>
                    <li>Samsung Galaxy A73 5G</li>
                    </ul></p>
                <p>
                    Here are a few my favourite photos I captured over the years to
                    keep the artistic side of me alive. I'm always looking to
                    push myself creatively and learn new things.
                </p>
            </div>
            <div className="text-block">
                <h3>Bird Photography</h3>
                <br />
                <p>
                    In high school, I started learning how to capture photos & came to know about the work principal of camera. As I capturing photos over the years, I
                    learned so much about the world in general, it taught me some key life expertise like:
                    <ul>
                        <li><b>Patience:</b> Bird photography instills the virtue of waiting quietly for the perfect moment, teaching us the value of persistence and observation.</li>
<li><b> Attention to Detail:</b> It hones our ability to notice subtle movements, colors, and behaviors, fostering a deeper appreciation for the beauty and diversity of avian life.</li>
<li><b>Connection to Nature:</b> Through capturing birds in their natural habitats, we develop a stronger bond with the environment, nurturing a sense of stewardship and respect for the delicate ecosystems they inhabit.</li>
                    </ul>
                </p>
                <br />
                <div className="captioned-image">
                        <img src={b1} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Bird Photography 1</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={b2} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Bird Photography 2</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={b3} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Bird Photography 3</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={b4} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Bird Photography 4</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={b5} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Bird Photography 5</b>
                            </sub>
                        </p>
                    </div>
            </div>
            <div className="text-block">
                <h3>Social Photography</h3>
                <br />
                <p>
                    Social Photography is the domain which I likes most. From seeing a go-to place with a different perspective to getting valuable life lesson from different peoples by their life stories it always attracts me.
                     Some crusial skills can be taught from social photography. Those are:
                    <ul>
                        <li><b>Storytelling:</b> Social photography teaches us the art of capturing moments that tell compelling stories, whether it's documenting personal experiences, cultural events, or societal issues.</li>
                        <li><b>Perspective:</b> It encourages us to see the world through different lenses, fostering empathy, understanding, and appreciation for diverse viewpoints and experiences captured through photography.</li>
                    </ul>
                </p>
                <br />
                <div className="captioned-image">
                        <img src={p1} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 1</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={p2} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 2</b>
                            </sub>
                        </p>
                    </div>
               <div className="captioned-image">
                        <img src={p3} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 3</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={p4} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 4</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={p5} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 5</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={p6} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 6</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={p7} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Social Photography 7</b>
                            </sub>
                        </p>
                    </div>
            </div>
            <div className="text-block">
                <h3>Landscape Photography</h3>
                <br />
                <p>
                    Everyone as a beginner when first got a camera, first of all they tried to capture some scenic beauty. But over time by knowing the camera their shot being improved and it always remind the days from where my creativity journey started.
                    Here are some of the shots I captured-
                </p>
                <br />
                <div className="captioned-image">
                        <img src={s1} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Landscape Photography 1</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={s2} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Landscape Photography 2</b>
                            </sub>
                        </p>
                    </div>
                <div className="captioned-image">
                        <img src={s3} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Landscape Photography 3</b>
                            </sub>
                        </p>
                    </div>
               <div className="captioned-image">
                        <img src={s4} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Landscape Photography 4</b>
                            </sub>
                        </p>
                    </div>
                <br />
                <p>
                    If you have any queries regarding anything without being hesitated feel free to connect me through
                    {' '}
                    <a href="mailto:sayak.helan@gmail.com">
                        email</a>. Or get in touch through my twitter handel {' '}
                        <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/aami_Sayak"
                    >
                        @aami_sayak
                    </a> 😉.
                </p>
            </div>
            
        </div>
        
    );
};
const styles: StyleSheetCSS = {

    caption: {
        height: 'auto',
        width: '80%',
    },
};
export default Art;
