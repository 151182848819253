import React from 'react';
import college from '../../components/showcase/college.png';
import school from '../../components/showcase/school.png';
import ResumeDownload from './ResumeDownload';

export interface ExperienceProps {}

const Experience: React.FC<ExperienceProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1 style={{ marginLeft: 10 }}>Education</h1>
            <ResumeDownload />
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h2><b>Graduation</b></h2>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={'https://tib.edu.in/'}
                        >
                            <h4>www.tib.edu.in</h4>
                        </a>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>B.tech in Computer Science & Engineering</h3>
                        <b>
                            <p>September 2020 - June 2024</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                I completed my B.Tech with an overall <b>9.25</b> cgpa. During my B.Tech journey in Computer Science and Engineering (CSE) at Techno International Batanagar, I embarked on a transformative academic voyage filled with growth, challenges, and achievements. From the very onset, I immersed myself in the dynamic world of programming languages, algorithms, and software engineering principles, laying a solid foundation for my technical prowess. Engaging lectures, hands-on projects, and collaborative learning environments honed my problem-solving skills and fostered a deep passion for innovation.
                </p>
                <br />
                <div className="captioned-image">
                        <img src={college} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Figure 1:</b> My College Techno International Batanagar
                            </sub>
                        </p>
                    </div>
                <p>Through rigorous coursework and practical experiences, I delved into diverse domains such as artificial intelligence, data science, and cybersecurity, broadening my horizons and preparing me for the ever-evolving landscape of technology. Beyond academics, I actively participated in extracurricular activities, hackathons, and workshops, nurturing my leadership abilities and fostering lifelong friendships. As I stride towards the culmination of my B.Tech journey, I am equipped with not only the knowledge and skills to excel in the field but also the confidence and resilience to tackle any challenge that comes my way, all thanks to the enriching experience at Techno International Batanagar.</p>
            </div>
            <div style={styles.headerContainer}>
                <div style={styles.header}>
                    <div style={styles.headerRow}>
                        <h2><b>Higher Secondary</b></h2>
                    </div>
                    <div style={styles.headerRow}>
                        <h3>Completed with PCM</h3>
                        <b>
                            <p>May 2018 - August 2020</p>
                        </b>
                    </div>
                </div>
            </div>
            <div className="text-block">
                <p>
                I completed my HS with <b>87.40%</b> marks.My Higher Secondary journey with a science stream at Sekendarpur Rai K.P. Pal Bahadur High School has been an enriching and transformative experience. Over the past two years, I delved deep into the realms of physics, chemistry, and mathematics, navigating through complex theories and practical applications. The rigorous curriculum challenged me to think critically, problem-solve creatively, and develop a profound understanding of the natural world. From conducting intricate experiments in the laboratory to solving intricate mathematical equations, every moment has been a stepping stone towards my academic growth.
                </p>
                <br />
                <div className="captioned-image">
                        <img src={school} style={styles.caption} alt="" />
                        <p>
                            <sub>
                                <b>Figure 2:</b> My School Sekendarpur Rai K.P. Pal Bahadur High School (H.S.)
                            </sub>
                        </p>
                    </div>
                <p>Moreover, the guidance and support of my teachers have been instrumental in shaping my academic pursuits, instilling in me a passion for scientific inquiry and discovery. As I prepare to embark on the next chapter of my educational journey, I am grateful for the solid foundation and invaluable experiences garnered during my time at Sekendarpur Rai K.P. Pal Bahadur High School.</p>
            </div>
        </div>
    );
};

const styles: StyleSheetCSS = {
    header: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
    },
    headerContainer: {
        alignItems: 'flex-end',
        width: '100%',
        justifyContent: 'center',
    },
    headerRow: {
        justifyContent: 'space-between',
        alignItems: 'flex-end',
    },
    caption: {
        height: 'auto',
        width: '80%',
    },
};

export default Experience;
