import React from 'react';
// @ts-ignore
import first from '../../../assets/pictures/projects/software/first.mp4';
// @ts-ignore
import computer from '../../../assets/pictures/projects/software/computer.mp4';
// @ts-ignore
import terminal from '../../../assets/pictures/projects/software/terminal.mp4';
// @ts-ignore
import sneaker from '../../../assets/pictures/projects/software/sneaker.mp4';
// @ts-ignore
import sy from '../../../assets/pictures/projects/software/sy.mp4';
// @ts-ignore
import todo from '../../../assets/pictures/projects/software/todo.mp4';
// @ts-ignore
import age from '../../../assets/pictures/projects/software/age.mp4';
// @ts-ignore
import night from '../../../assets/pictures/projects/software/night.mp4';
// @ts-ignore
import ht from '../../../assets/pictures/projects/software/ht.mp4';
import ResumeDownload from '../ResumeDownload';
import VideoAsset from '../../general/VideoAsset';

export interface SoftwareProjectsProps {}

const SoftwareProjects: React.FC<SoftwareProjectsProps> = (props) => {
    return (
        <div className="site-page-content">
            <h1>Web Sites</h1>
            <h3>For Showcasing My Skills</h3>
            <br />
            <p>
                Below are some of my favorite website projects I have worked on
                over the last few years.
            </p>
            <br />
            <ResumeDownload />
            <br />
            <div className="text-block">
                <h2>My Portfolio</h2>
                <br />
                <p>
                    This is my portfolio website, and also the
                    website you are on right now. This project was an absolute
                    joy to make and challenged me both technically and
                    creatively. Early in 2023, I knew I wanted to make an
                    interactive portfolio to aid my job search. I eventually got
                    the idea for this site around early August and began
                    development early January of 2024. I've been developing it alongside
                    my last semester at college and if you are reading this, it's
                    pretty much done!
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={computer} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 1:</b> Blender Scene of the 3D website.
                            The scene from Blender was baked and exported in a
                            GLTF format.
                        </sub>
                    </p>
                </div>
                <p>
                    Now, a quick technical breakdown of the site. The website is
                    split into two parts, the 3D site, and the 2D OS site. The
                    3D site uses Three.js to render the scene and renders the 2D
                    site inside of it using an iframe. The 2D OS site is a
                    simple react site that is hosted{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://os.imsayak.com/"
                    >
                        here
                    </a>{' '}
                    and works as a standalone web app. The actual rendering of
                    the 2D site is accomplished using a CSS renderer provided by
                    Three.js that transforms the html of the 2D site with 3D CSS
                    transforms to give the illusion of three dimensionality.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://www.imsayak.com/"
                        >
                            <p>
                                <b>[3D Site]</b> - https://www.imsayak.com/
                            </p>
                        </a>
                    </li>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://os.imsayak.com/"
                        >
                            <p>
                                <b>[OS Site]</b> - https://os.imsayak.com/
                            </p>
                        </a>
                    </li>

                </ul>
                <p>
                    I'm skipping over a lot of details in exchange for brevity,
                    but I do plan on doing a more in depth breakdown for those
                    interested sometime in the future. To get updates with that
                    project feel free to follow me on twitter{' '}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/aami_Sayak"
                    >
                        @aami_sayak
                    </a>
                </p>
            </div>
            <div className="text-block">
                <h2>Terminal- Portfolio</h2>
                <br />
                <p>
                    Its also a portfolio website. Basically its my portfolio website in terminal version developed with React, TypeScript and Styled-Components. Multiple themes supported and keyboard shortcuts can be used for some functionalities.
                </p>
                <br />
                <p>Here are some key features of this site- 
                    <ul>
                        <li>Responsive Design </li>
                        <li>Autocomplete feature "(TAB | Ctrl + i)"</li>
                        <li>Multiple themes </li>
                        <li>View command history </li>
                        <li>Go previous and next command </li>
                        <li>PWA and Offline Support </li>
                    </ul>
                </p>
                <div className="captioned-image">
                    <VideoAsset src={terminal} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 2:</b> The terminal site
                        </sub>
                    </p>
                </div>
                <p>
                    It was a really fun project to work on and
                    had a multitude of challenges. Many techs are using to make this website & I'm using those techs for the first time. Techs which were used here- 
                    <ul>
                        <li>Frontend - React, TypeScript</li>
                        <li>Styling - Styled-Components</li>
                        <li>UI/UX - Figma</li>
                        <li>State Management - ContextAPI</li>
                        <li>Testing - Vitest, React Testing Library</li>
                        <li>Deployment - Cloudflare</li>
                    </ul>
                    It was a surprisingly difficult challenge as it
                    intersected both art and programming, but was fun to work on
                    overall.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://terminal.imsayak.com/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://terminal.imsayak.com/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Sneaker Head</h2>
                <br />
                <p>
                I'm excited to share the journey of creating our sneaker ecommerce website. As a developer, my role was to bring our vision to life online. It all started with brainstorming ideas and designing the layout to make it user-friendly. 
                </p>
                <br />
                <p>Then, I got to work coding the website, making sure it works smoothly on all devices like phones and computers. Testing was crucial to catch any bugs and ensure everything functions perfectly. Finally, I launched my website, ready for sneaker enthusiasts to explore and shop their favorite kicks hassle-free. It's been a rewarding journey, and I can't wait to see my website grow as a project among students."
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={sneaker} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 3:</b> The Sneaker Head site
                        </sub>
                    </p>
                </div>
                <p>
                    Techs which were used here- 
                    <ul>
                        <li>Semantic HTML5 markup</li>
                        <li>Mobile-first workflow</li>
                        <li>React - JS library</li>
                        <li>Tailwindcss - CSS framework</li>
                        <li>Ionicons - Icons</li>
                        <li>MongoDB - NoSQL database</li>
                        <li>Nodejs - Back-end JavaScript runtime environment</li>
                        <li>Express - Nodejs framework</li>
                        <li>Stripe - Online payment service provider</li>
                        <li>Redux Toolkit - Toolset for efficient Redux development</li>
                    </ul>
                    It was fun to work on this project.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://sneakers-z66o.onrender.com/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://sneakers-z66o.onrender.com/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Space Yatra</h2>
                <br />
                <p>
                Tried to build a cutting-edge space tourism website that would captivate visitors from all corners of the globe. Armed with the latest technologies and a passion for innovation, tried set out to make this dream a reality.
                </p>
                <br />
                <p>As I embarked on this journey into the cosmos of web development, My dedication propelled me toward my ultimate goal: to launch a space tourism website that would inspire, inform, and delight users around the world.
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={sy} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 4:</b> The Space Yatra site
                        </sub>
                    </p>
                </div>
                <p>
                    It was a really fun project to work on and
                    had a multitude of challenges. Many techs are using to make this website. Techs which were used here- 
                    <ul>
                        <li>Flexbox </li>
                        <li>CSS Grid</li>
                        <li>Mobile-first workflow</li>
                        <li>TypeScript - Programming language</li>
                        <li>React - JS library</li>
                        <li>Next.js - React framework</li>
                        <li>React Transition Group - React library</li>
                        <li>PostCSS - JS tool</li>
                        <li>TailwindCSS - CSS framework</li>
                    </ul>
                    It was a surprisingly difficult challenge but was fun to work on
                    overall.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://spaceyatra.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://spaceyatra.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>TO-DO List</h2>
                <br />
                <p>
                The process of building a simple todo website. As a developer, I started by outlining the basic features I wanted - a clean interface to add tasks, mark them as completed, and delete them when necessary.</p>
                <br />
                <p>Then, I dove into coding, using HTML for structure, CSS for styling, and JavaScript for functionality. I focused on keeping it user-friendly, with intuitive design and smooth interactions. Testing was crucial; I made sure everything worked smoothly across different devices and browsers. Finally, after some tweaks and improvements, the todo website was ready to help users organize their tasks efficiently. It's a rewarding journey turning ideas into a functional website!"
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={todo} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 5:</b> The TO-DO List site
                        </sub>
                    </p>
                </div>
                <p>
                    It was a really fun project to work on. Techs which were used here- 
                    <ul>
                        <li>Frontend - HTML,CSS</li>
                        <li>Styling - Styled-Components</li>
                        <li>UI/UX - Javascript</li>
                        <li>Deployment - Vercel</li>
                    </ul>
                    It was a surprisingly fun to work on
                    overall.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://mine-todo.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://mine-todo.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Age Calculator</h2>
                <br />
                <p>
                I recently built a cool age calculator website using React with Vite. It was a fun project where I utilized the power of React to create a dynamic user interface. With Vite, the development process was super smooth and fast.
                </p>
                <br />
                <p>I structured the app components to efficiently handle user inputs and perform calculations to determine age. By leveraging React's state management, I ensured a seamless user experience, updating results in real-time as users input their birthdate. Overall, it was a rewarding experience crafting this handy tool, and I'm excited to share it with others!"
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={age} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 6:</b> The Age Calculator site
                        </sub>
                    </p>
                </div>
                <p>
                    Many techs are using to make this website. Techs which were used here- 
                    <ul>
                        <li>Semantic HTML5 markup</li>
                        <li>CSS custom properties</li>
                        <li>Flexbox</li>
                        <li>Mobile-first workflow</li>
                        <li>Tailwindcss - CSS framework</li>
                        <li>TypeScript</li>
                        <li>Redux Toolkit - Toolset for efficient Redux development</li>
                        <li>React - JS library</li>
                        <li>Vite - JS tool</li>
                        <li>React libraries/packages: dayjs, react-hook-form, react-redux, react-countup</li>
                    </ul>
                    It was fun to work on.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://mine-age.vercel.app/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://mine-age.vercel.app/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Hacker-Type (CLONE)</h2>
                <br />
                <p>
                As a developer, I embarked on creating a hacker-type clone website, diving into the world of coding and creativity. It all started with envisioning the layout and functionalities, sketching ideas on a whiteboard, and translating them into code.
                </p>
                <br />
                <p>As a developer, I embarked on creating a hacker-type clone website, diving into the world of coding and creativity. It all started with envisioning the layout and functionalities, sketching ideas on a whiteboard, and translating them into code.
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={ht} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 7:</b> The Hacker-Type clone site
                        </sub>
                    </p>
                </div>
                <p>
                    Techs which were used here- 
                    <ul>
                        <li>Frontend - HTML, CSS</li>
                        <li>Styling - Styled-Components</li>
                        <li>UI/UX - Javascript</li>
                        <li>Deployment - Cloudflare</li>
                    </ul>
                    It was a surprisingly challenging to work on.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://hackertyper-b5n.pages.dev/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://hackertyper-b5n.pages.dev/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>Night Sky</h2>
                <br />
                <p>
                I think it's time to share the process of creating a website that resembles a serene night sky.
                </p>
                <br />
                <p>As a developer, I started by envisioning the tranquil beauty of the night sky with its stars and moon. Using HTML, CSS, and JavaScript, I crafted the layout and design elements to mimic the darkness and depth of the night. I incorporated animations to simulate twinkling stars and a gentle breeze. With careful coding and attention to detail, I brought this virtual night sky to life, offering visitors a peaceful digital escape under the stars.
                </p>
                <br/>
                <div className="captioned-image">
                    <VideoAsset src={night} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 8:</b> The Night Sky site
                        </sub>
                    </p>
                </div>
                <p>
                    It was a really fun project to work on, Techs which were used here- 
                    <ul>
                        <li>Frontend - HTML, CSS</li>
                        <li>Styling - Styled-Components</li>
                        <li>UI/UX - Figma</li>
                        <li>State Management - Javascript</li>
                        <li>Deployment - Cloudflare</li>
                    </ul>
                    It was a surprisingly difficult challenge as it
                    developing something for the first time on my own, but was fun to work on
                    overall.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://1-bd6.pages.dev/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://1-bd6.pages.dev/
                            </p>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="text-block">
                <h2>First Portfolio</h2>
                <br />
                <p>
                Creating a fully responsive personal portfolio website using HTML, CSS, and JavaScript is an excellent way to showcase my skills and projects to potential employers or clients. With responsiveness for all devices, my website adapt seamlessly to various screen sizes, providing a consistent and user-friendly experience.
                </p>
                <br />
                <div className="captioned-image">
                    <VideoAsset src={first} />
                    <p style={styles.caption}>
                        <sub>
                            <b>Figure 9:</b> My first Portfolio
                        </sub>
                    </p>
                </div>
                <p>
                HTML serves as the foundation for structuring my content, including sections such as a home page, about me, projects, and contact information. CSS and JavaScript is essential for styling my website, ensuring it looks visually appealing and aligns with my personal branding. Using media queries in CSS enables me to implement a responsive design, adjusting layout and styling based on the device's screen size.
                </p>
                <br />
                <h3>Links:</h3>
                <ul>
                    <li>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href="https://sayakfolio.pages.dev/"
                        >
                            <p>
                                <b>[Site Link]</b> - https://sayakfolio.pages.dev/
                            </p>
                        </a>
                    </li>
                </ul>
                <p>
                    If you are a student and wanted to build a personal portfolio then without any hesitation feel free to connect me through
                    {' '}
                    <a href="mailto:sayak.helan@gmail.com">
                        email</a>. Or get the code from my {' '}
                        <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://github.com/Sayakbhattacharyya"
                    >
                        github
                    </a> profile 😉.
                </p>
                <br/>
                <p>This page is currently a work in progress. I've recently
                    came into this developing domain and for having simultenous college classes doesn't get enough time to create new projects but I
                    have some project plans and ideas in the works. Expect
                    updates to this page as I'm recently diving into the world of development!</p>
            </div>
            <ResumeDownload />
        </div>
    );
};

const styles: StyleSheetCSS = {
    video: {
        width: '100%',
        padding: 12,
    },
    caption: {
        width: '80%',
    },
};

export default SoftwareProjects;
